import { HStack, Box, Text, VStack } from "@chakra-ui/react";
import "./IntroCnnex.css";
import introNet from "../images/network.png";
import { useState, useEffect } from 'react';


const IntroCnnex = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (

        <div className="intro-container">
            {
            screenWidth > 905 ?
            <HStack spacing={3}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
            >
                <Box height={"500px"}
                     width={"35%"}
                     display={"flex"}
                     flexDirection={"column"}
                     justifyContent={"center"}
                     alignItems={"center"} >
                    <img src={introNet} width={"350px"} alt="in" />
                </Box>

                    <Box height={"500px"}
                        width={"35%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"start"}
                    >
                        <VStack spacing={3}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"start"}
                                >
                            <Text fontSize={ "3xl" } as={'b'}>Why CnnexVPN?</Text>
                            {/* <Text fontSize={"sm"}>
                            With the aid of state-of-the-art technology, CnnexVPN safeguards your devices against malware and ensures the confidentiality of your browsing activities. The encryption employed is so robust that it would take the most powerful computer in the world billions of years to decipher your data — a testament to its unparalleled security measures.                        
                            </Text> */}
                            <Text fontSize={"sm"}>
                            Cnnex VPN facilitates seamless switching between Chinese and American websites, offering comprehensive privacy protection within the region. Moreover, it enables effortless access to international sites, providing users with unparalleled content diversity and ensuring an optimal browsing experience.
                            </Text>
                            <Text fontSize={"sm"}>
                            Cnnex VPN offers access to numerous international sites via its extensive network of thousands of servers. Our dedicated 24/7 customer service team stands ready to address any user inquiries promptly and effectively, ensuring a seamless and reliable user experience.
                            </Text>
                        </VStack>
                    </Box>

            </HStack>
             : 
            <VStack height={"100%"}
                    width={"100%"} 
                    paddingTop={"25px"}>
                <Box 
                     width={"100%"}
                     display={"flex"}
                     flexDirection={"column"}
                     justifyContent={"center"}
                     alignItems={"center"} 
                     marginBottom={"25px"}>
                    <img src={introNet} width={"300px"} alt="in" />
                </Box>
                <VStack spacing={3}
                        width={"90%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"start"} 
                        marginBottom={"25px"}>
                    <Text fontSize={"xl"} as={'b'}>Why CnnexVPN?</Text>
                    <Text fontSize={"sm"}>
                    Cnnex VPN facilitates seamless switching between Chinese and American websites, offering comprehensive privacy protection within the region. Moreover, it enables effortless access to international sites, providing users with unparalleled content diversity and ensuring an optimal browsing experience.
                    </Text>
                    <Text fontSize={"sm"}>
                    Cnnex VPN offers access to numerous international sites via its extensive network of thousands of servers. Our dedicated 24/7 customer service team stands ready to address any user inquiries promptly and effectively, ensuring a seamless and reliable user experience.
                    </Text>
                </VStack>

            </VStack>


            }
        </div>
    );
}

export default IntroCnnex;