import { HStack, Box, Text, VStack } from "@chakra-ui/react";
import "./LogoBar.css"
import youtube from "../images/YouTube_Logo_2017.png"
import xtwietter from "../images/X_logo_2023_original.png"
import linkedin from "../images/LinkedIn_icon.png"
import tencent from "../images/Tencent_Video.png"
import iqiyi from "../images/IQiyi_logo.png"
import youku from "../images/Youku_logo.png"
import fb from "../images/Facebook_f_logo.png"
import insta from "../images/Instagram_logo_2016.png"
import gmail from "../images/Gmail_icon.png"
import gpt from "../images/ChatGPT_logo.png"
import { useState, useEffect } from 'react';


const LogoBar = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <VStack className="logobar-container" spacing={3} marginBottom={"30px"} >
            {screenWidth >= 905 ? 
            <HStack spacing={10}
                    // height={"90px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"} >

                <img src={youtube} width={ screenWidth < 905 ? "60px" : "100px" } alt="yt" />
                <img src={xtwietter} width={ screenWidth < 905 ? "12px" : "20px" } alt="xt" />
                <img src={linkedin} width={ screenWidth < 905 ? "15.5px" : "27px" } alt="li" />
                <img src={tencent} width={ screenWidth < 905 ? "18px" : "30px" } alt="tc" />
                <img src={iqiyi} width={ screenWidth < 905 ? "42px" : "70px" } alt="iqi" />
                <img src={youku} width={ screenWidth < 905 ? "60px" : "100px" } alt="yk" />
                <img src={fb} width={ screenWidth < 905 ? "19.8px" : "33px" } alt="fb" />
                <img src={insta} width={ screenWidth < 905 ? "19.8px" : "33px" } alt="is" />
                <img src={gmail} width={ screenWidth < 905 ? "22.2px" : "37px" } alt="gm" />
                <img src={gpt} width={ screenWidth < 905 ? "19.8px" : "33px" } alt="gpt" />

            </HStack> :
            <VStack width={"100%"} spacing={7} paddingBottom={"15px"}>
                <HStack spacing={5}
                        width={"90%"}
                        justifyContent={"center"}>
                    <img src={youtube} width={ "60px" } alt="yt" />
                    <img src={xtwietter} width={ "12px" } alt="xt" />
                    <img src={linkedin} width={ "15.5px" } alt="li" />
                    <img src={tencent} width={ "18px" } alt="tc" />
                    <img src={iqiyi} width={ "42px" } alt="iqi" />
                </HStack>
                <HStack spacing={5}
                        width={"90%"}
                        justifyContent={"center"}>
                    <img src={youku} width={ "60px" } alt="yk" />
                    <img src={fb} width={ "19.8px" } alt="fb" />
                    <img src={insta} width={ "19.8px" } alt="is" />
                    <img src={gmail} width={ "22.2px" } alt="gm" />
                    <img src={gpt} width={ "19.8px" } alt="gpt" />
                </HStack>
            </VStack>

            }

            <Box 
                 width={"90%"}
                 display={"flex"}
                 alignItems={"center"}
                 justifyContent={"center"} >
                <Text fontSize={ screenWidth < 905 ? "md" : "2xl"} lineHeight={screenWidth < 905 ? "35px" : "60px"}>
                Cybersecurity involves safeguarding computer systems, networks, and data against unauthorized access, damage, or theft. 
                VPNs play a crucial role in safeguarding personal privacy, thwarting data breaches, defending against malware attacks, and upholding business trust.
                A VPN also lets you access region-restricted content.
                </Text>
            </Box>

        </VStack>
    );
}

export default LogoBar;