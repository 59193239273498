import { Text, HStack, Stack, VStack } from "@chakra-ui/react";
import "./DownloadAllApps.css";
import windows from "../images/Windows_logo.png"
import mac from "../images/MacOS_logo.png"
import android from "../images/Android_logo_2019.png"
import ios from "../images/IOS_logo.png"
import { useState, useEffect } from "react";


const DownloadAllApps = () => {
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="all-apps-container">
            {screenWidth >= 905 ? 
            <Stack spacing={9}
                   justifyContent={"center"}
                   alignItems={"center"}>
                <Text fontSize={"4xl"} as={'b'}>Get the Cnnex App for all your devices</Text>
                <Text>
                    Securely connect up to 20 devices and enjoy private internet access, even while connected to public Wi-Fi networks.
                </Text>
                <HStack width={"100%"}
                        spacing={10}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                    <img src={windows} width={"130px"} alt="windows" />
                    <img src={mac} width={"40px"} alt="mac" />
                    <img src={android} width={"50px"} alt="android" />
                    <img src={ios} width={"45px"} alt="ios" />
                </HStack>
                <Text fontSize={"md"} color={"gray"}>Password manager is upcoming</Text>
            </Stack> :
            <VStack width={"90%"}>
                <Text fontSize={"xl"} as={'b'}>Get the Cnnex App for all your devices</Text>
                <br />
                <Text fontSize={"xs"}>
                    Securely connect up to 20 devices and enjoy private internet access, even while connected to public Wi-Fi networks.
                </Text>
                <br />
                <HStack width={"100%"}
                        spacing={10}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                    <img src={windows} width={"78px"} alt="windows" />
                    <img src={mac} width={"24px"} alt="mac" />
                    <img src={android} width={"30px"} alt="android" />
                    <img src={ios} width={"27px"} alt="ios" />
                </HStack>
                <br />
                <Text fontSize={"xs"} color={"gray"}>Password manager is upcoming</Text>


            </VStack> }

        </div>
    );
}

export default DownloadAllApps;