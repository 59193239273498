import { Box, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import "./RectWithButton.css"
import { useState, useEffect } from "react";


const ReactWithButtons = (props) => {

    const [ is2Clicked, set2Clicked ] = useState(true);
    const [ is1Clicked, set1Clicked ] = useState(false); 
    const [ isMClicked, setMClicked ] = useState(false);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick2Year = () => {
        set2Clicked(true);
        set1Clicked(false);
        setMClicked(false);
        props.changePlan("2");
    }

    const handleClick1Year = () => {
        set1Clicked(true);
        set2Clicked(false);
        setMClicked(false);
        props.changePlan("y");
    }

    const handleClickMonth = () => {
        setMClicked(true);
        set1Clicked(false);
        set2Clicked(false);
        props.changePlan("m");
    }

    return (
        <Box style={{ width: "90%", 
                      border: "1px solid #ccc", 
                      borderRadius: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", 
                      paddingLeft: "15px", 
                      paddingRight: "15px", 
                      paddingTop: "20px", 
                      paddingBottom: "20px" }} >
            { screenWidth > 905 ? 
                <HStack spacing={10} width={"100%"} >
                    <button className="button" onClick={handleClick2Year} 
                            style={{backgroundColor: is2Clicked && "#ddd"}}>2-year plan</button>
                    <button className="button" onClick={handleClick1Year}
                            style={{backgroundColor: is1Clicked && "#ddd"}}>1-year plan</button>
                    <button className="button" onClick={handleClickMonth}
                            style={{backgroundColor: isMClicked && "#ddd"}}>monthly plan</button>
                </HStack>
                :
                // <VStack spacing={5} width={"100%"}>
                //     <button className="button" onClick={handleClick2Year} 
                //             style={{backgroundColor: is2Clicked && "#ddd", width: "90%" }}>2-year plan</button>
                //     <button className="button" onClick={handleClick1Year}
                //             style={{backgroundColor: is1Clicked && "#ddd", width: "90%"}}>1-year plan</button>
                //     <button className="button" onClick={handleClickMonth}
                //             style={{backgroundColor: isMClicked && "#ddd", width: "90%"}}>monthly plan</button>
                // </VStack>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink isCurrentPage onClick={ handleClick2Year } as={ is2Clicked && 'b'}>2-year plan</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={ handleClick1Year } as={is1Clicked && 'b'} >1-year plan</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem >
                        <BreadcrumbLink onClick={ handleClickMonth } as={isMClicked && 'b'} >monthly plan</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            }
            
            {/* <Box className="rect">
                <HStack spacing={5}>
                    <button className="button">2 - Year Plan</button>

                    <button className="button">1 - Year Plan</button>
                </HStack>
            </Box> */}
        </Box>
    );

};

export default ReactWithButtons;