import "../App.css"
import ReactWithButtons from "../components/RectWithButton";
import PriceTable from "../components/PriceTable";
import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/MobileMenu";



const PricingPage = () => {

    const [ plan, setPlan ] = useState("2");


    const onClickPlan = (option) => {
        setPlan(option);
    }

    return (
        <div>
            <Header />
            <Menu />
        <div className="price-container">
            <ReactWithButtons changePlan={onClickPlan}/>
            <PriceTable plan={plan}/>
        </div>
            <Footer />
        </div>
    );
}

export default PricingPage;