import { Text, VStack } from "@chakra-ui/react";
import "./WhyCnnexIntro.css"
import { useState, useEffect } from "react";


const WhyCnnexIntro = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="why-intro-container">
            <VStack spacing={7} width={"90%"} align={ screenWidth >= 905 ? "center" : "start"}>
                <Text fontSize={ screenWidth >= 905 ? "4xl" : "xl" } as={'b'}>Reliable Security Features for Your Trust</Text>
                <Text fontSize={ screenWidth >= 905 ? "lg" : "sm" }>Empower Your Online Safety Anywhere, Anytime. Safeguard Your Access to Apps, Websites, and Entertainment with Confidence.</Text>
                <Text fontSize={ screenWidth >= 905 ? "lg" : "sm" }>Explore Eastern and Western Cultures with a Single Click.</Text>
            </VStack>
        </div>
    );
}

export default WhyCnnexIntro;