import "./DownloadMain.css"
import { useGlobalData } from "../context/GlobalDataContext";
import { HStack, VStack, Text, Button, Box } from "@chakra-ui/react";
import macImg from "../images//macApp.png";
import winImg from "../images/windowsApp.png";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


const DownloadMain = () => {
    const { osInfo } = useGlobalData();
    const nav = useNavigate();
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="download-main-container">
            { screenWidth >= 905 ? 
            <HStack width={"66%"}
                    height={"100%"}
                    >
                <VStack spacing={3}
                        width={"50%"}
                        height={"100%"}
                        padding={5}
                        justifyContent={"center"}
                        alignItems={"start"}
                        >
                    <Text fontSize={"4xl"} as={'b'}>Download the Securest VPN app for {osInfo === "MacIntel"? "Mac" : "Windows"}</Text>
                    <Text color={"gray"}>&#10023; Free: Includes Daily Free Emergency Connect</Text>
                    <Text color={"gray"}>&#10023; Freedom: Includes United States and China Sites</Text>
                    <Text color={"gray"}>&#10023; Secure: no user log and global coverage</Text>
                    <br />
                    <HStack spacing={6}>
                        <Button width={"150px"} height={"40px"} borderRadius={"20px"} colorScheme="red"
                                onClick={() => nav("/price")}
                                >Buy Cnnex</Button>
                        <Button width={"150px"} 
                                height={"40px"} 
                                borderRadius={"20px"} 
                                colorScheme="white" 
                                color={"red"} 
                                border={"1px red solid"}
                                className="download-button">Download App</Button>

                    </HStack>
                </VStack>
                <VStack spacing={7}
                        width={"50%"}
                        height={"100%"}
                        justifyContent={"center"}>
                    <Box backgroundColor={"lightblue"}
                         height={"300px"}
                         width={"300px"}
                         display={"flex"}
                         borderRadius={"50%"}
                         justifyContent={"center"}
                         alignItems={"center"}>
                        {osInfo === "MacIntel" ? 
                        <img src={macImg} width={"200px"} alt="mac-app" />:
                        <img src={winImg} width={"200px"} alt="win-app" />
                        } 
                    </Box>
                </VStack>

            </HStack >
            : 
            <VStack spacing={3}
                    width={"90%"}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"start"}>
                <Text fontSize={"2xl"} as={'b'}>Download the Securest VPN app for {osInfo === "MacIntel"? "Mac" : "Windows"}</Text>
                <Text fontSize={"sm"} color={"gray"}>&#10023; Free: Includes Daily Free Emergency Connect</Text>
                <Text fontSize={"sm"} color={"gray"}>&#10023; Freedom: Includes United States and China Sites</Text>
                <Text fontSize={"sm"} color={"gray"}>&#10023; Secure: no user log and global coverage</Text>
                <br />
                <HStack width={"70%"} justifyContent={"center"}>
                    <Box backgroundColor={"lightblue"}
                            height={"200px"}
                            width={"200px"}
                            display={"flex"}
                            borderRadius={"50%"}
                            justifyContent={"center"}
                            alignItems={"center"}>
                            {osInfo === "MacIntel" ? 
                            <img src={macImg} width={"120px"} alt="mac-app" />:
                            <img src={winImg} width={"120px"} alt="win-app" />
                            } 
                    </Box>
                </HStack>
                <br />
                <HStack spacing={6}>
                        <Button width={"150px"} height={"40px"} borderRadius={"20px"} colorScheme="red"
                                onClick={() => nav("/price")}
                                >Buy Cnnex</Button>
                        <Button width={"150px"} 
                                height={"40px"} 
                                borderRadius={"20px"} 
                                colorScheme="white" 
                                color={"red"} 
                                border={"1px red solid"}
                                className="download-button">Download App</Button>

                </HStack>

            </VStack>
            }
        </div>
    );
}

export default DownloadMain;