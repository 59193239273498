import { VStack, Text, HStack, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastForward, faLock, faGlobe, faLocation, faMobile, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";


const WhyFeatures = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (

        
        <VStack spacing={12}
                paddingTop={"35px"}
                paddingBottom={"35px"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}>
            { screenWidth >= 905 ? 
            <div>
                <Text fontSize={"4xl"} as={'b'}>Experience Security and Freedom with Cnnex VPN</Text>
                <HStack spacing={7}>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faLock} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Internet Security</Text>
                        <br />
                        <Text>Trustworthy VPNs provide strong online security and enhance your digital privacy.</Text>
                    </Box>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faGlobe} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Both US And CN</Text>
                        <br />
                        <Text>Easily Switch Between Chinese and American Services with a Single Click.</Text>
                    </Box>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faLocation} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Hide Your IP</Text>
                        <br />
                        <Text>Using a VPN to mask your IP address is the most secure method to safeguard privacy.</Text>
                    </Box>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faFastForward} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Express VPN</Text>
                        <br />
                        <Text>Experience lightning-fast speeds. Browse, stream, and download without any worries.</Text>
                    </Box>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faMobile} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Diverse Devices</Text>
                        <br />
                        <Text>Safeguard mulitple different model devices with a single Cnnex account.</Text>
                    </Box>
                    <Box height="300px"
                        width="180px"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faUser} size="4x"/>
                        <br />
                        <Text fontSize={"xl"}>Anonymous</Text>
                        <br />
                        <Text>No personal information is required to register and log in to the account.</Text>
                    </Box>
                </HStack>
            </div> :
            <VStack width={"90%"} align={"start"} >
                <Text fontSize={"xl"} as={'b'}>Experience Security and Freedom with Cnnex VPN</Text>
                <br />
                <HStack spacing={7}>
                <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faLock} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Internet Security</Text>
                        <br />
                        <Text fontSize={"xs"}>Trustworthy VPNs provide strong online security and enhance your digital privacy.</Text>
                    </Box>
                    <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faGlobe} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Both US And CN</Text>
                        <br />
                        <Text fontSize={"xs"} >Easily Switch Between Chinese and American Services with a Single Click.</Text>
                    </Box>
                </HStack>

                <br />

                <HStack spacing={7}>
                    <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1} >
                        <FontAwesomeIcon color="orange" icon={faLocation} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Hide Your IP</Text>
                        <br />
                        <Text fontSize={"xs"}>Using a VPN to mask your IP address is the most secure method to safeguard privacy.</Text>
                    </Box>
                    <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faFastForward} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Express VPN</Text>
                        <br />
                        <Text fontSize={"xs"}>Experience lightning-fast speeds. Browse, stream, and download without any worries.</Text>
                    </Box>
                </HStack>

                <br />
                <HStack spacing={7}>
                <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faMobile} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Diverse Devices</Text>
                        <br />
                        <Text fontSize={"xs"}>Safeguard mulitple different model devices with a single Cnnex account.</Text>
                    </Box>
                    <Box height="240px"
                        width="40%"
                        border={"1px solid lightgray"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        borderRadius={"11px"}
                        padding={1}>
                        <FontAwesomeIcon color="orange" icon={faUser} size="3x"/>
                        <br />
                        <Text fontSize={"lg"}>Anonymous</Text>
                        <br />
                        <Text fontSize={"xs"}>No personal information is required to register and log in to the account.</Text>
                    </Box>
                </HStack>

            </VStack> }
        </VStack> 
    );
}

export default WhyFeatures;