import { HStack, Text, VStack } from "@chakra-ui/react";
import "./Footer.css"
import { ReactComponent as Android } from "../images/google-play.svg";
import { ReactComponent as Apple } from "../images/app-store.svg";
import { useState, useEffect } from 'react';


const Footer = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="foot-container">

            <VStack width={"100%"} 
                spacing={7}
                height={"100%" } >

            {
                screenWidth > 905 ? 
                
                <HStack width={"90%"}
                        justifyContent={"space-between"}
                >
                    <Text>Download the Cnnex VPN mobile app for iOS or Android.</Text>
                    <HStack spacing={5}>
                        <button onClick={() => {console.log("Android")}} >
                        <Android />
                        </button>
                        <Apple />
                    </HStack>
                </HStack> :

                <VStack width={"90%"}
                        justifyContent={"space-between"}
                        spacing={5}
                >
                    <Text fontSize={ "xs" }>Download the Cnnex VPN mobile app for iOS or Android.</Text>
                    <HStack spacing={5}>
                        <button onClick={() => {console.log("Android")}} >
                        <Android />
                        </button>
                    <Apple />
                    </HStack>
                </VStack>

            }



            <hr style={{ width: '90%', borderColor: "gray" }} />
            {
                screenWidth > 905 ? 
            
            <HStack width={"90%"}
                    spacing={10} >
                <Text>About Us</Text>
                <Text>Careers</Text>
                <Text>Partner Plan</Text>
                <Text>Student Discount</Text>
                <Text>Refer a Friend</Text>
                <Text>Contact Us</Text>
                <Text>Privacy Policy</Text>
                <Text>Terms of Service</Text>
            </HStack> :
            <HStack width={"90%"} spacing={7} height={"100%"} alignItems={"top"}>
                <VStack width={"30"}  height={"100%"} align={"start"} justifyContent={"top"}>
                    <Text fontSize={"12px"}>About Us</Text>
                    <Text fontSize={"12px"}>Refer a Friend</Text>
                    <Text fontSize={"12px"}>Student Discount</Text>
                </VStack>
                <VStack width={"30"}  height={"100%"} align={"start"} justifyContent={"top"} >
                    <Text fontSize={"12px"}>Careers</Text>
                    <Text fontSize={"12px"}>Contact Us</Text>
                    <Text fontSize={"12px"}>Terms of Service</Text>
                </VStack>
                <VStack width={"30"} height={"100%"} align={"start"} justifyContent={"top"}>
                    <Text fontSize={"12px"}>Partner Plan</Text>
                    <Text fontSize={"12px"}>Privacy Policy</Text>
                </VStack>

            </HStack>

            }

            <HStack width={"90%"}>
                <Text fontSize={ screenWidth > 905 ? "sm" : "xs"} >© 2024 Cnnex Limited Company. All rights reserved</Text>
            </HStack>

            </VStack>

        </div>
    );

}

export default Footer;