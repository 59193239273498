import "./WhyQuestion.css"
import { Text, Box, VStack, HStack } from "@chakra-ui/react";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";


const WhyQestion = () => {

    const [ isFirstExpand, setFirstExpand ] = useState(false);
    const [ isSecondExpand, setSecondExpand ] = useState(false);
    const [ isThirdExpand, setThirdExpand ] = useState(false);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="why-question-container">
            <VStack spacing={4}
                    width={"100%"}>
                <Text fontSize={ screenWidth >= 905 ? "4xl" : "xl" } as={'b'} marginBottom={"15px"}>Frequently asked questions</Text>
                <Box width= {screenWidth >= 905 ? "35%" : "90%"}
                     backgroundColor={"white"}
                     padding={5}
                     borderRadius={"7px"}
                     onClick={() => setFirstExpand(!isFirstExpand)}>
                    
                    <HStack justifyContent={"space-between"}>
                        <Text fontSize={ screenWidth >= 905 ? "lg" : "md" } as={'b'}>Is it worth having a VPN?</Text>
                        {isFirstExpand ? <FontAwesomeIcon icon={faArrowUp} size="1x" />:
                         <FontAwesomeIcon icon={faArrowDown} size="1x" />}
                    </HStack>
                    {isFirstExpand && <br /> }
                    {isFirstExpand && <Text fontSize={screenWidth >= 905 ? "md" : "xs"} >Absolutely, VPNs encrypt your internet traffic, making it more secure and private. If protecting your online privacy and security is important to you, then a VPN can be worth it.</Text>}
                    {isFirstExpand && <br /> }
                    {isFirstExpand && <Text fontSize={screenWidth >= 905 ? "md" : "xs"} >VPNs can help you bypass geo-restrictions and access content that may be blocked in your region.</Text>}
                </Box>
                <Box width={screenWidth >= 905 ? "35%" : "90%"}
                     backgroundColor={"white"}
                     padding={5}
                     borderRadius={"7px"}
                     onClick={() => setSecondExpand(!isSecondExpand)}>
                    
                    <HStack justifyContent={"space-between"}>
                        <Text fontSize={ screenWidth >= 905 ? "lg" : "md" } as={'b'}>Does Cnnex VPN support membership upgrades?</Text>
                        {isSecondExpand ? 
                         <FontAwesomeIcon icon={faArrowUp} size="1x" />:
                         <FontAwesomeIcon icon={faArrowDown} size="1x" /> }
                    </HStack>
                    {isSecondExpand && <br /> }
                    {isSecondExpand && <Text fontSize={ screenWidth >= 905 ? "md" : "xs" } >Upgrade Options: Members have the flexibility to seamlessly upgrade to higher-tier memberships at any time, unlocking the enhanced benefits associated with their new membership level.</Text>}
                </Box>
                <Box width={screenWidth >= 905 ? "35%" : "90%"}
                     backgroundColor={"white"}
                     padding={5}
                     borderRadius={"7px"}
                     onClick={() => setThirdExpand(!isThirdExpand)}>
                    
                    <HStack justifyContent={"space-between"}>
                        <Text fontSize={ screenWidth >= 905 ? "lg" : "md" } as={'b'}>Do you earn rewards for referring friends? </Text>
                        {isThirdExpand ? 
                        <FontAwesomeIcon icon={faArrowUp} size="1x" />:
                        <FontAwesomeIcon icon={faArrowDown} size="1x" /> }
                    </HStack>
                    {isThirdExpand && <br /> }
                    {isThirdExpand && <Text fontSize={ screenWidth >= 905 ? "md" : "xs" } >Absolutely. We offer rewards for any successful referral. The amount of rewards varies depending on the number of referrals. For more information, please visit the "Refer a Friend" section on our homepage. Additionally, if you excel at referring trusted friends, you may qualify for our "Partner Program"</Text>}
                </Box>
            </VStack>
        </div>
    );

}

export default WhyQestion;