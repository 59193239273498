
import "./Theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import bkImage from '../images/th_5.jpg';
import { Text, Box, HStack, VStack, Button } from "@chakra-ui/react";
import { useState, useEffect } from 'react';

const Theme = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const nav = useNavigate();

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div
            className='theme-container'
            style={{ backgroundImage : `url(${bkImage})`}}
        >
            <HStack width={"100%"}>
                <Box
                    // height={"700px"}
                    width={screenWidth < 905 ? "100%" : "40%"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={screenWidth < 905 ? "center" : "end"}
                    justifyContent={"center"}
                    marginLeft={"5%"}
                >
                    <VStack spacing={8}
                            alignItems={"start"}
                            marginEnd={"40px"}
                            >
                        <Text fontSize={screenWidth < 905 ? "lg" : "xl"} as={'b'}>In the AI era, you need security and freedom more</Text>
                        <Box>
                            <Text fontSize={ screenWidth < 905 ? "2xl" : "4xl"} fontFamily={"Arial"} as={"b"}>Get 43% off Cnnex VPN</Text>
                            <Text fontSize={ screenWidth < 905 ? "2xl" : "4xl"}>+3 months extra </Text>
                        </Box>
                        <VStack spacing={3}
                                alignItems={"start"}
                                color={"gray"}>
                            <Text fontSize={ screenWidth < 905 ? "md" : "lg"} fontFamily={"Arial"} >Protect yourself online: VPN security in a click</Text>
                            <Text fontSize={ screenWidth < 905 ? "md" : "lg"}>Network shuttle between US and China</Text>
                            <Text fontSize={ screenWidth < 905 ? "md" : "lg"}>Unlimited traffic</Text>
                            <Text fontSize={ screenWidth < 905 ? "md" : "lg"}>Free emergency connect</Text>
                        </VStack>
                        <Button colorScheme='orange' 
                            height={"30px"}
                            borderRadius={"15px"}
                            fontSize={"sm"}
                            onClick={() => nav("/price")}>
                            Get the Deal
                        </Button>
                    </VStack>
                </Box>
                <div className="shield-icon" >
                    <Box
                        height={"700px"}
                        width={"20%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <FontAwesomeIcon color="#4CAF50" icon={ faShield } size="7x" />
                    </Box>

                </div>
            </HStack>
        </div>
    );

}

export default Theme