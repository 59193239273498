
import { Box, HStack, Text, VStack, Button } from "@chakra-ui/react";
import "./PriceTable.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

const accountSiteUrl = process.env.REACT_APP_CHECKOUT_URL;

const PriceTable = (props) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [ planPrice, setPlanPrice ] = useState({
        exclusivePlan: "",
        platinumPlan: "",
        goldenPlan: "",
        basicPlan: "",
    });

    const [ discount, setDiscount ] = useState({
        exclusivePlan: "",
        platinumPlan: "",
        goldenPlan: "",
        basicPlan: "",
    });

    useEffect(() => {
        if (props.plan === "2") {
            setPlanPrice({
                exclusivePlan: "$22.99/mo",
                platinumPlan: "$6.99/mo",
                goldenPlan: "$4.99/mo",
                basicPlan: "$3.99/mo",
            });
            setDiscount({
                exclusivePlan: "43% off",
                platinumPlan: "30% off",
                goldenPlan: "38% off",
                basicPlan: "20% off",
            });
        } else if (props.plan === "y") {
            setPlanPrice({
                exclusivePlan: "$33.33/mo",
                platinumPlan: "$8.33/mo",
                goldenPlan: "$6.67/mo",
                basicPlan: "$4.17/mo",
            });
            setDiscount({
                exclusivePlan: "17% off",
                platinumPlan: "17% off",
                goldenPlan: "17% off",
                basicPlan: "17% off",
            });
        } else if (props.plan === "m") {
            setPlanPrice({
                exclusivePlan: "$39.99/mo",
                platinumPlan: "$9.99/mo",
                goldenPlan: "$7.99/mo",
                basicPlan: "$4.99/mo",
            });     
            setDiscount({
                exclusivePlan: "",
                platinumPlan: "",
                goldenPlan: "",
                basicPlan: "",
            });   
        }
    }, [props])


    const plans = [
        {
            id: 21,
            name: "Exclusive Member",
            memberType: "ee",
            price: planPrice.exclusivePlan,
            discount: discount.exclusivePlan,
            bonus: "+3 Extra months",
            button_name: "Be Exclusive",
            devices_limited: "10 devices for United States & 10 devices China Sites respectively",
            unlock_all_sites_cost: "free",
            users_per_channel: "Your own channel",
            service_level: "24 hours/7 days",
            upgradable: "-",
            
        },
        {
            id: 22,
            name: "Platinum Member",
            memberType: "pm",
            price: planPrice.platinumPlan,
            discount: discount.platinumPlan,
            bonus: "+2 Extra months",
            button_name: "Be Platinum",
            devices_limited: "5 Ddvices for United States & 5 devices China Sites respectively",
            unlock_all_sites_cost: "$19.99/year",
            users_per_channel: "10 users share one channel",
            service_level: "8 hours/5 days",
            upgradable: "Yes",
        },
        {
            id: 23,
            name: "Golden Member",
            memberType: "gn",
            price: planPrice.goldenPlan,
            discount: discount.goldenPlan,
            bonus: "+1 Extra months",
            button_name: "Be Golden",
            devices_limited: "4 Ddvices for United States & 4 devices China Sites respectively",
            unlock_all_sites_cost: "$19.99/year",
            users_per_channel: "25 users share one channel",
            service_level: "8 hours/5 days",
            upgradable: "Yes",
        },
        {
            id: 24,
            name: "Basic Member",
            memberType: "bc",
            price: planPrice.basicPlan,
            discount: discount.basicPlan,
            bonus: "+1 Extra months",
            button_name: "Be Member",
            devices_limited: "3 Ddvices for United States & 3 devices China Sites respectively",
            unlock_all_sites_cost: "$19.99/year",
            users_per_channel: "50 users share one channel",
            service_level: "8 hours/5 days",
            upgradable: "Yes",
        },
    ];

    const handleClickedButton = (memberType, payType) => {
        var servicePlan = memberType + payType;
        // console.log(servicePlan);
        // nav(`/checkout/${servicePlan}`);
        // window.open(`http://localhost:3005/checkout/${servicePlan}`);
        window.open(`${accountSiteUrl}/checkout/${servicePlan}`);
    };


    return (
        <div className="table-container">
            <HStack width={"100%"}
                    align={"start"} >
                <Text fontSize={"2xl"} color={"gray"} as={'b'}>Hightlights</Text>
            </HStack>
            <br />
            <HStack width={"100%"} 
                    justifyContent={"space-between"}
                    backgroundColor={"white"}
                    padding={"15px"}
                    lineHeight={"30px"}
                    paddingLeft={ screenWidth > 905 ? "5%" : ""}
                    borderRadius={"15px"}
            >
                <Box width={"100%"} 
                     display={"flex"}
                     flexDirection={"column"}
                     alignItems={"start"}>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>High-speed VPN, Data Encryption</Text>
                        </HStack>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>Switch United States and China sites with one click.</Text>
                        </HStack>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>All plans provide unlimited traffic.</Text>
                        </HStack>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>One hour of free emergency connection site per day.</Text>
                        </HStack>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>Anonymous sign up options.</Text>
                        </HStack>
                        <HStack spacing={3}>
                            <Text color={"tomato"}>&#9737;</Text>
                            <Text fontSize={ screenWidth < 905 && "sm"}>All members have benefilts for referral.</Text>
                        </HStack>                     
                </Box>

                { screenWidth > 905 && 
                <Box width={"40%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box width={"200px"}
                         height={"200px"}
                         backgroundColor={"skyblue"}
                         borderRadius={"50%"}
                         display={"flex"}
                         justifyContent={"center"}
                         alignItems={"center"}>
                        <FontAwesomeIcon icon={faHighlighter} color="tomato" size="7x" />
                    </Box>
                </Box>
                }
                
            </HStack>

            <br />

            {
                plans.map((plan) => (
                    <VStack width={"100%"}
                            align={"start"} 
                            background={"white"}
                            padding={"15px"} 
                            key={plan.id} 
                            borderRadius={"10px"} 
                            marginBottom={"25px"} 
                            alignItems={"center"} >
                        <HStack width={"90%"} >
                            <HStack width={"50%"} align={"start"} >
                                <Text fontSize={ screenWidth > 905 ? "xl" : "lg"} as={'b'}>{plan.name}</Text>
                            </HStack>
                            <HStack width={"50%"} justifyContent={"end"} >
                                <Text>{plan.price}</Text>  
                            </HStack>                   
                        </HStack>
                        <HStack width={"90%"}>
                            <HStack width={"50%"} align={"start"} spacing={7} >
                                <Text color={plan.name === "Exclusive Member" ? "red" : "black"} as={'b'}>{plan.discount}</Text>
                                {props.plan === 2 && <Text color={"red"} as={'b'}>{plan.bonus}</Text>}
                            </HStack>
                            <HStack width={"50%"} justifyContent={"end"} >
                                <Button colorScheme="red" width={"150px"} 
                                        fontSize={"sm"} height={"30px"} borderRadius={"15px"}
                                        onClick={() => handleClickedButton(plan.memberType, props.plan)}
                                        >
                                    {plan.button_name}
                                </Button>
                            </HStack>
                        </HStack>
                        <HStack width={"90%"}>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" } >{plan.devices_limited}</Text>
                        </HStack>
                        <HStack width={"90%"}>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" } >How to unlock 10+ contries/regions resources: </Text>
                            <Text as={'b'}>{plan.unlock_all_sites_cost}</Text>
                        </HStack>
                        <HStack width={"90%"}>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" }>{plan.users_per_channel}</Text>
                        </HStack>
                        <HStack width={"90%"}>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" }>Service Level: </Text>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" }>{plan.service_level}</Text>
                        </HStack>
                        <HStack width={"90%"} >
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" }>Upgradable: </Text>
                            <Text fontSize={ screenWidth > 905 ? "md" : "sm" }>{plan.upgradable}</Text>
                        </HStack>
                        <br />
                    </VStack>
                ))
            }
            <br />
            <br />

        </div>
    );
}

export default PriceTable;