import { Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import WhyCnnexPage from './pages/WhyCnnexPage';
import { GlobalDataProvider } from './context/GlobalDataContext';
import OSInfo from './utility/OSInfo';
import DownloadPage from './pages/DownloadPage';
import { MobileMenuProvider } from './context/mobileMenuContext';

function App() {

  return (
    <ChakraProvider>
      <GlobalDataProvider>
        <MobileMenuProvider>
          <main>
            {/* <Header /> */}
            <Routes>
              <Route path='/' element={<HomePage />}></Route>
              <Route path='/price' element={<PricingPage />}></Route>
              <Route path='/why' element={<WhyCnnexPage />}></Route>
              <Route path='/download' element={<DownloadPage />}></Route>
            </Routes>
            <OSInfo />
            {/* <Footer /> */}
          </main>
        </MobileMenuProvider>
      </GlobalDataProvider>
    </ChakraProvider>
  );

}

export default App;
