import "../App.css"
import DownloadAllApps from "../components/DownloadAllApps";
import DownloadBenefit from "../components/DownloadBenefit";
import DownloadMain from "../components/DownloadMain";
import DownloadProcess from "../components/DownloadProcess";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/MobileMenu";

const DownloadPage = () => {
    return (
        <div>
            <Header />
            <Menu />
            <div className="why-container">
                
                <DownloadMain />
                <DownloadProcess />
                <DownloadAllApps />
                <DownloadBenefit />
                
            </div>
            <Footer />
        </div>
    );
}

export default DownloadPage;