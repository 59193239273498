import WhyCnnexIntro from "../components/WhyCnnexIntro";
import "../App.css"
import WhyFeatures from "../components/WhyFeatures";
import WhyService from "../components/WhyService";
import WhyQestion from "../components/WhyQuestion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/MobileMenu";

const WhyCnnexPage = () => {
    return (
        <div >
            <Header />
            <Menu />
            <div className="why-container">
                <WhyCnnexIntro />
                <WhyFeatures />
                <WhyService />
                <WhyQestion />
            </div>
            <Footer />
        </div>

    );
}

export default WhyCnnexPage;