import { HStack, Text, VStack, Button } from "@chakra-ui/react";
import "./DownloadBenefit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faLocation, faPassport, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


const DownloadBenefit = () => {
    const nav = useNavigate();
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="download-benefit-container">
            
            { screenWidth >= 905 ? 
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Text fontSize={"4xl"} as={'b'}>Exploring the Advantages of Using a VPN App</Text>
            <br />
            <HStack width={"80%"}
                    height={"60%"}
                    justifyContent={"center"}
                    spacing={5}>
                <VStack width={"18%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faEyeSlash} size="2x" color="skyblue" />
                    <Text fontSize={"xl"} as={'b'}>Enhanced Security</Text>
                    <Text fontSize={"sm"} color={"gray"}>VPNs encrypt your internet connection, making it harder for hackers and third parties to intercept your data. This is crucial, especially when using public Wi-Fi networks where your information is more vulnerable to attacks.</Text>
                </VStack>
                <VStack width={"18%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faLocation} size="2x" color="skyblue" />
                    <Text fontSize={"xl"} as={'b'}>Privacy Protection</Text>
                    <Text fontSize={"sm"} color={"gray"}>VPNs hide your IP address and replace it with one from their server, masking your online identity and making it difficult for websites and advertisers to track your browsing habits.</Text>
                </VStack>
                <VStack width={"18%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faPassport} size="2x" color="skyblue" />
                    <Text fontSize={"xl"} as={'b'}>Access Content</Text>
                    <Text fontSize={"sm"} color={"gray"}>With a VPN, you can bypass geographical restrictions and access content that may be blocked in your region. This includes streaming services, social media platforms, and websites that may be censored by governments or ISPs.</Text>
                </VStack>
                <VStack width={"18%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faSignOut} size="2x" color="skyblue" />
                    <Text fontSize={"xl"} as={'b'}>Anonymous Browsing</Text>
                    <Text fontSize={"sm"} color={"gray"}>By routing your internet traffic through a VPN server, you can browse the web anonymously, protecting your online activities from surveillance and maintaining your digital privacy.</Text>
                </VStack>
            </HStack>
            <Button width={"150px"} height={"40px"} borderRadius={"20px"} colorScheme="red" onClick={() => nav("/price")}>Get Cnnex</Button>
            </div>
            : 
            <VStack width={"90%"}>
                <Text fontSize={"xl"} as={'b'}>Exploring the Advantages of Using a VPN App</Text>
                <br />
                <VStack width={"100%"}
                    justifyContent={"center"}
                    spacing={5}>
                <VStack width={"100%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faEyeSlash} size="1x" color="skyblue" />
                    <Text fontSize={"lg"} as={'b'}>Enhanced Security</Text>
                    <Text fontSize={"xs"} color={"gray"}>VPNs encrypt your internet connection, making it harder for hackers and third parties to intercept your data. This is crucial, especially when using public Wi-Fi networks where your information is more vulnerable to attacks.</Text>
                </VStack>
                <VStack width={"100%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faLocation} size="1x" color="skyblue" />
                    <Text fontSize={"lg"} as={'b'}>Privacy Protection</Text>
                    <Text fontSize={"xs"} color={"gray"}>VPNs hide your IP address and replace it with one from their server, masking your online identity and making it difficult for websites and advertisers to track your browsing habits.</Text>
                </VStack>
                <VStack width={"100%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faPassport} size="1x" color="skyblue" />
                    <Text fontSize={"lg"} as={'b'}>Access Content</Text>
                    <Text fontSize={"xs"} color={"gray"}>With a VPN, you can bypass geographical restrictions and access content that may be blocked in your region. This includes streaming services, social media platforms, and websites that may be censored by governments or ISPs.</Text>
                </VStack>
                <VStack width={"100%"}
                        height={"100%"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        padding={2}>
                    <FontAwesomeIcon icon={faSignOut} size="1x" color="skyblue" />
                    <Text fontSize={"lg"} as={'b'}>Anonymous Browsing</Text>
                    <Text fontSize={"xs"} color={"gray"}>By routing your internet traffic through a VPN server, you can browse the web anonymously, protecting your online activities from surveillance and maintaining your digital privacy.</Text>
                </VStack>
                <Button width={"150px"} height={"40px"} borderRadius={"20px"} colorScheme="red" onClick={() => nav("/price")}>Get Cnnex</Button>
            </VStack>
            </VStack> }
        </div>
    );

}

export default DownloadBenefit;