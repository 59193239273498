import { createContext, useContext, useState } from "react";


const GlobalDataContext = createContext(undefined);


export const GlobalDataProvider = ({ children }) => {
    const [ osInfo, setOsInfo ] = useState("");
    const [ isCheckout, setIsCheckout ] = useState(false);

    return (
        <GlobalDataContext.Provider
            value={{
                osInfo,
                baseUrl: "https://www.h-yao.io:8443",
                setGlobalOsInfo: (os) => setOsInfo(os),
                isCheckout: isCheckout,
                setCheckoutPage: (ic) => setIsCheckout(ic),
            }}
        >
            {children}
        </GlobalDataContext.Provider>
    );
};

export const useGlobalData = () => useContext(GlobalDataContext);

