import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";
import "./Header.css"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import { useMobileMenuContext } from "../context/mobileMenuContext";
// import bcrypt from "bcryptjs-react";


const Logo = require("../logos/logo.ico")
const accountSiteUrl = process.env.REACT_APP_ACCOUNT_SITE_URL;


const Header = () => {
    const headerRef = useRef(null)
    const nav = useNavigate();
    // const [ openMenu, setOpenMenu ] = useState(false);

    const { isOpen, onOpen, onClose } = useMobileMenuContext();

    const [ hovered, setHovered ] = useState({
        price: false,
        why: false,
        download: false,
        signin: false,
        account: false,
    })

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        nav("/");
        // console.log("Home");
    };

    const handleNav = (path) => {
        nav(path);
    }

    return (
        <Box
            left={0}
            top={0}
            right={0}
            translateY={0}
            backgroundColor={"#18181b"}
            // backgroundColor={"#E8E8Eb"}
            transitionDuration={".3s"}
            transitionProperty={"transform"}
            transitionTimingFunction={"ease-in-out"}
            height={"73px"}
            zIndex={1999}
            ref={headerRef}
        >
            <Box color={"white"} maxWidth={"1450px"} margin={"0 auto"}>
                <HStack justifyContent={"space-between"} width={"100%"}>
                    <HStack justifyContent={"start"} alignItems={"center"} spacing={20} >
                        <HStack py={5} px={8} justifyContent={"start"} alignItems={"center"} alignContent={"center"}>
                            {/* <button onClick={scrollToTop} className="logo"> */}
                                <img src={Logo} width={"40px"} height={"40px"} alt="logo" style={{ cursor: "pointer" }} onClick={scrollToTop}/>
                            {/* </button> */}
                            <Text style={{marginBottom: "2px", cursor: "pointer"}} fontSize={"xl"} as={'b'} onClick={() => nav("/")}>CNNEX</Text>
                        </HStack>
                        

                        <nav style={{width: "70%", display: "flex", justifyContent: "start"}} >
                            <HStack spacing={7}>
                                <button onClick={() => handleNav('/price')} 
                                        className="nav-menu" 
                                        onMouseEnter={() => setHovered(prevState => ({
                                            ...prevState,  // Copy the existing state
                                            price: true,  // Update the specific property
                                        }))}
                                        onMouseLeave={() => setHovered(prevState => ({
                                            ...prevState,
                                            price: false,
                                        }))}>
                                    Pricing {hovered["price"] && <div className="underline" />}
                                    {/* <Text fontSize={'md'}>Pricing</Text> */}
                                </button>
                                <button onClick={() => handleNav('/why')}
                                        className="nav-menu" 
                                        onMouseEnter={() => setHovered(prevState => ({
                                            ...prevState,
                                            why: true,
                                        }))}
                                        onMouseLeave={() => setHovered(prevState => ({
                                            ...prevState,
                                            why: false,
                                        }))}>
                                    Why Cnnex VPN? {hovered["why"] && <div className="underline" />}
                                    {/* <Text fontSize={'md'}>Why Cnnex VPN?</Text> */}
                                </button>
                                <button onClick={() => handleNav('/download')}
                                        className="nav-menu"
                                        onMouseEnter={() => setHovered(prevState => ({
                                            ...prevState,
                                            download: true
                                        }))}
                                        onMouseLeave={() => setHovered(prevState => ({
                                            ...prevState, 
                                            download: false,
                                        }))}>
                                    Download Cnnex {hovered["download"] && <div className="underline" />}
                                    {/* <Text fontSize={'md'}>Download Cnnex</Text> */}
                                </button>
                            </HStack>
                        </nav>
                    </HStack>
                    <HStack spacing={5}>
                        <a className="nav-menu" 
                            style={{marginBottom: "5px"}} 
                            onMouseEnter={(prevState) =>  setHovered ({
                                ...prevState,
                                account: true
                            })}
                            onMouseLeave={(prevState) => setHovered ({
                                ...prevState,
                                account: false,
                            })}
                            href={accountSiteUrl} target="_blank" rel="noopener noreferrer">
                            Account { hovered["account"] && <div className="underline" /> }
                        </a> 
                        <button style={{marginBottom: "5px"}} 
                                className="nav-menu"
                                // onClick={() => window.open(`http://localhost:1102/login/${shortHash}`, '_blank')}
                                onClick={() => window.open(accountSiteUrl, "_blank")}
                                onMouseEnter={() => setHovered(prevState => ({
                                    ...prevState, 
                                    signin: true,
                                }))}
                                onMouseLeave={() => setHovered(prevState => ({
                                    ...prevState,
                                    signin: false,
                                }))}
                                >
                            Log In {hovered["signin"] && <div className="underline" />}
                        </button>
                        <div style={{ marginRight: "25px" }} onClick={() => {isOpen? onClose() : onOpen()}} >
                            <FontAwesomeIcon icon={ isOpen ? faXmark : faBars } size="2x" className="bars"  />
                        </div>
                        <div className="nav-menu">
                            <Button style={{marginBottom: "7px"}} 
                                    colorScheme='red' 
                                    height={"30px"}
                                    borderRadius={"15px"}
                                    onClick={() => window.open(accountSiteUrl, "_blank")} 
                                    >
                                        Sign Up
                            </Button>
                        </div>

                    </HStack>
                </HStack>
            </Box>

        </Box>
        
    );

}

export default Header;