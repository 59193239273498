import "./DownloadProcess.css";
import { HStack, Text, VStack, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


const DownloadProcess = () => {
    const nav = useNavigate();
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="process-container">
            { screenWidth >= 905 ? 
            <VStack width={"65%"}
                    height={"100%"}
                    padding={10}
                    textAlign={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}>
                <Text fontSize={"4xl"} as={'b'}>How to get a Cnnex VPN app for moble devices</Text>
                <br />
                <HStack justifyContent={"center"}
                        width={"81%"}>
                    <VStack>
                        <Text fontSize={"3xl"} color={"#cb3c07"}>&#10122;</Text>
                        <Text fontSize={"xl"}>Signup</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"3xl"} color={"#cb3c07"}>&#10123;</Text>
                        <Text fontSize={"xl"}>Login</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"3xl"} color={"#cb3c07"}>&#10124;</Text>
                        <Text fontSize={"xl"}>Download</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"3xl"} color={"#cb3c07"}>&#10125;</Text>
                        <Text fontSize={"xl"}>Connect</Text>
                    </VStack>
                </HStack>
                <br />
                <Text>And that’s it. You’ve just encrypted your mobile traffic and hidden your IP address on your phone or tablet. This adds an extra layer of security and privacy to your online activities. And have free access to global content at any time.</Text>
                <br />
                <Button width={"150px"} height={"40px"} borderRadius={"20px"} colorScheme="red" onClick={() => nav("/price")}>Get Cnnex</Button>
            </VStack> :
            <VStack width={"90%"} >
                <Text fontSize={"xl"} as={'b'}>How to get a Cnnex VPN app for moble devices</Text>
                <br />
                <HStack justifyContent={"center"}
                        width={"100%"}>
                    <VStack>
                        <Text fontSize={"2xl"} color={"#cb3c07"}>&#10122;</Text>
                        <Text fontSize={"xs"}>Signup</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"2xl"} color={"#cb3c07"}>&#10123;</Text>
                        <Text fontSize={"xs"}>Login</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"2xl"} color={"#cb3c07"}>&#10124;</Text>
                        <Text fontSize={"xs"}>Download</Text>
                    </VStack>
                    <div style={{height: "2px", border: "1px solid #cb3c07", width: "40%"}}></div>
                    <VStack>
                        <Text fontSize={"2xl"} color={"#cb3c07"}>&#10125;</Text>
                        <Text fontSize={"xs"}>Connect</Text>
                    </VStack>
                </HStack>
            <br />
            <Text fontSize={"xs"}>And that’s it. You’ve just encrypted your mobile traffic and hidden your IP address on your phone or tablet. This adds an extra layer of security and privacy to your online activities. And have free access to global content at any time.</Text>
            <br />
            <Button width={"150px"} height={"30px"} borderRadius={"15px"} colorScheme="red" onClick={() => nav("/price")}>Get Cnnex</Button>

            </VStack> }
        </div>
    );
}

export default DownloadProcess;