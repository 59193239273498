import "./HomeDownload.css"

import { HStack, Text, VStack } from "@chakra-ui/react"
import windows from "../images/Windows_logo.png"
import mac from "../images/MacOS_logo.png"
import android from "../images/Android_logo_2019.png"
import ios from "../images/IOS_logo.png"
import { useState, useEffect } from 'react';


const HomeDownload = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="home-download-container">
            <VStack spacing={7} width={"90%"} >
                <Text fontSize={screenWidth > 905 ? "4xl" : "xl"} as={'b'}>
                Applications for Your Devices
                </Text>
                <Text fontSize={screenWidth < 905 && "sm"}>
                Securely connect up to 20 devices and enjoy private internet access, even while connected to public Wi-Fi networks.
                </Text>
                <HStack width={"90%"}
                        spacing={7}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                    <img src={windows} width={ screenWidth > 905 ? "130px" : "78px"} alt="windows" />
                    <img src={mac} width={ screenWidth > 905 ? "40px" : "24px"} alt="mac" />
                    <img src={android} width={ screenWidth > 905 ? "50px" : "30px"} alt="android" />
                    <img src={ios} width={ screenWidth > 905 ? "45px" : "27px"} alt="ios" />
                </HStack>
                <Text fontSize={screenWidth > 905 ? "4xl" : "xl" } as={'b'}>
                Free Emergency Connect
                </Text>
                <Text fontSize={screenWidth < 905 && "sm"}>
                To ensure a smooth user experience, we provide complimentary emergency link resources for one hour daily, mitigating any potential disruptions during usage.
                </Text>
            </VStack>


        </div>
    );
}

export default HomeDownload;