import "./WhyService.css"
import { HStack, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFileText, faPhone } from "@fortawesome/free-solid-svg-icons";
import wechat from "../images/WeChat_logo.png";
import whatsapp from "../images/WhatsApp.png";
import { useState, useEffect } from "react";


const WhyService = () => {
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className="whyservice-container">
            <Text fontSize={ screenWidth >= 905 ? "4xl" : "xl" } as={'b'}>7/24 Service Support</Text>
            <br />
            <br />
            <HStack spacing={ screenWidth >= 905 ? 12 : 5} width={"90%"} justifyContent={"center"}>
                <VStack>
                    <FontAwesomeIcon icon={faEnvelope} size= { screenWidth >= 905 ? "4x" : "2x" } />
                    <Text>Email</Text>
                </VStack>
                <VStack>
                    <img src={wechat} width={ screenWidth >= 905 ? "75px" : "40px" } alt="wechat"/>
                    <Text marginStart={"-7PX"}>Wechat</Text>
                </VStack>
                <VStack>
                    <img src={whatsapp} width={ screenWidth >= 905 ? "57px" : "35px" } alt="whatapp"/>
                    <Text marginStart={"-7PX"}>WhatsApp</Text>
                </VStack>
                <VStack>
                    <FontAwesomeIcon icon={faFileText} size= { screenWidth >= 905 ? "4x" : "2x"} />
                    <Text>Message</Text>
                </VStack>
                <VStack>
                    <FontAwesomeIcon icon={faPhone} size={ screenWidth >= 905 ? "4x" : "2x" } />
                    <Text>Hotline</Text>
                </VStack>

            </HStack>
        </div>

    );

}

export default WhyService;