import React, { useEffect } from "react";
import { useGlobalData } from "../context/GlobalDataContext";



const OSInfo = () => {
    // const [ platform, setPlatform ] = useState('');
    const { setGlobalOsInfo } = useGlobalData();

    useEffect(() => {
        const platform = window.navigator.platform;
        // setPlatform(platform);
        setGlobalOsInfo(platform);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {/* {osInfo} */}
        </div>
    );

};


export default OSInfo;