
import { useEffect } from "react";
import HomeDownload from "../components/HomeDownload";
import IntroCnnex from "../components/IntroCnnex";
import LogoBar from "../components/LogoBar";
import Theme from "../components/Theme";
import { useGlobalData } from "../context/GlobalDataContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/MobileMenu";


const HomePage = () => {

    // const { osInfo } = useGlobalData();
    // console.log(osInfo);
    const { setCheckoutPage } = useGlobalData();
    useEffect(() => {
        setCheckoutPage(false);
    // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Header />
            <Menu />
            <Theme />
            <LogoBar />
            <IntroCnnex />
            <HomeDownload />
            <Footer />
        </div>
    );
}

export default HomePage;