import { useMobileMenuContext } from "../context/mobileMenuContext";
import { useNavigate } from "react-router-dom";
import "./MobileMenu.css";
import { useEffect, useRef } from "react";
import { VStack, Text } from "@chakra-ui/react";

const accountBaseUrl = process.env.REACT_APP_ACCOUNT_SITE_URL;

const MobileMenu = ({ children }) => {
    const { isOpen } = useMobileMenuContext();

    return (
        isOpen && (
            <>
                <div className="Overlay">
                    <div className="Menu" >
                        {children}
                    </div>
                </div>
            </>
        )
    );
};

const Menu = () => {
    const { onClose } = useMobileMenuContext();
    // const [ hoverPricing, setHoverPricing ] = useState(false);
    // const [ hoverWhy, setHoverWhy ] = useState(false);
    // const [ hoverDownload, setHoverDownload ] = useState(false);
    // const [ hoverAccount, setHoverAccount ] = useState(false);
    // const [ hoverLogin, setHoverLogin ] = useState(false);
    // const [ hoverSignup, setHoverSignup ] = useState(false);

    const componentRef = useRef(null);
    const nav = useNavigate();

    useEffect(() => {
        const handleMousePositionChange = (e) => {
            if (componentRef.current && e.clientY > 68 && 
                componentRef.current.getBoundingClientRect().width > 0 && componentRef.current.getBoundingClientRect().height > 0 &&
                (e.clientX < window.innerWidth - componentRef.current.getBoundingClientRect().width || 
                 e.clientY > componentRef.current.getBoundingClientRect().height + 68)) {
                onClose();
            }
        };

        window.addEventListener("mouseup", handleMousePositionChange);

        return () => {
            window.removeEventListener("mouseup", handleMousePositionChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    return (
        <MobileMenu>
            <VStack spacing={6}
                    p={6}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"space-between"}
                    ref={componentRef} >
                <VStack width={"90%"} 
                     height={"90%"} 
                     align={"start"}
                     spacing={5}
                     marginTop={"15px"}
                     >
                    <Text onClick={() => {
                            nav("/price");
                            onClose();
                          }} >
                        Pricing
                    </Text>
                    <Text onClick={() => {
                            nav("/why");
                            onClose();
                          }}
                    >Why Cnnex VPN?</Text>
                    <Text onClick={() => {
                            nav("/download");
                            onClose();
                          }}
                    >Download Cnnex</Text>
                    <Text onClick={() => {
                            window.open(accountBaseUrl, "_blank");
                            onClose();
                          }}
                    >Account</Text>
                    <Text onClick={() => {
                            window.open(accountBaseUrl, "_blank");
                            onClose();
                          }}
                    >Log in</Text>
                    <Text onClick={() => {
                            window.open(accountBaseUrl, "_blank");
                            onClose();
                          }}
                    >Sign up</Text>
                </VStack>
                
            </VStack>
        </MobileMenu>
    );

};

export default Menu;